<template>
	<section id="project" class="first-page">
		<p class="title">{{ $t('pages.project.title') }}</p>
		<div class="io-text-container">
			<p class="io-text">
				<!--Plataforma digital para os clínicos no combate ao Covid-19 tendo como principal objetivo
                a previsão da
                evolução da doença de um determinado doente.-->
				{{ $t('projectInfo.description.key1') }}
			</p>
			<br />
			<p class="io-text">
				{{ $t('projectInfo.description.key2') }}
			</p>
			<p class="io-text">
				<b>{{ $t('projectInfo.description.key3') }}</b>
			</p>
			<p class="io-text">
				<b>{{ $t('projectInfo.description.key4') }}</b>
			</p>
			<p class="io-text">
				<b>{{ $t('projectInfo.description.key5') }}</b>
			</p>
			<p class="io-text">
				{{ $t('projectInfo.description.key6') }}
			</p>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'Project',
		data() {
			return {};
		},
		methods: {},
	};
</script>

<style scoped lang="scss">
	@import '@/assets/scss/_variables';
	.title {
		margin-top: 50px;
		font-weight: 600;
		color: $primary;
	}

	.columns {
		margin: 50px 10% 50px 10% !important;
	}

	.io-text-container {
		margin-top: 0;
		margin-right: 10%;
		margin-left: 10%;
		margin-bottom: 10%;
	}

	.io-text {
		font-size: calc(min(5vw, 18px));
		color: $dark;
		text-align: justify;
		line-height: 2;
	}

	.card {
		border-radius: 20px;
		-webkit-box-shadow: 0 0.5em 1em -0.125em rgba(1, 172, 202, 0.3), 0 0px 0 1px rgba(1, 172, 202, 0.02);
		-moz-box-shadow: 0 0.5em 1em -0.125em rgba(1, 172, 202, 0.3), 0 0px 0 1px rgba(1, 172, 202, 0.02);
		box-shadow: 0 0.5em 1em -0.125em rgba(1, 172, 202, 0.3), 0 0px 0 1px rgba(1, 172, 202, 0.02);
	}

	img {
		width: 30%;
	}

	.first-page {
		background-color: $white;
	}
</style>
