<template>
	<section id="home" class="hero">
		<div class="columns">
			<div class="column">
				<h1 class="io-title">{{ $t('projectInfo.title') }}</h1>
				<h2 class="io-sub-title">{{ $t('projectInfo.subTitle') }}</h2>
				<div style="margin-bottom: 50px">
					<img class="is-hidden-desktop" src="../assets/images/banner-desktop.png" />
					<div hidden>
						<a href="http://www.freepik.com">Designed by vectorjuice / Freepik</a>
					</div>
				</div>
				<p class="project-code">
					{{ $t('pages.home.projectCode') }} <i>{{ $t('projectInfo.projectCode') }}</i>
				</p>
				<p class="io-text">{{ $t('projectInfo.shortDescription') }}</p>
				<br />
				<br />
				<a class="button is-primary is-medium is-rounded" :href="`${publicPath}ioCity_Ficha_de_Projeto.pdf`" target="_blank" download>
					{{ $t('pages.home.button') }}
				</a>
			</div>
			<div class="column is-hidden-touch has-position-relative">
				<img class="img-flat" src="../assets/images/banner-desktop.png" />
				<div hidden>
					<a href="http://www.freepik.com">Designed by vectorjuice / Freepik</a>
				</div>
			</div>
		</div>
		<div class="bottom-div">
			<div class="img-div">
				<p class="img-label">
					<b>{{ $t('pages.home.banner') }}</b>
				</p>
				<img class="banner" src="../assets/banner/barra_feder.png" />
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'Home',
		data() {
			return {
				publicPath: process.env.BASE_URL,
			};
		},
	};
</script>

<style scoped lang="scss">
	@import '@/assets/scss/_variables';
	.columns {
		margin-left: 25px;
		margin-right: 25px;
	}

	.img-flat {
		height: auto;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 30;
		pointer-events: none;
	}

	@media screen and (max-width: 1023px) {
		.button {
			margin-bottom: 20px;
		}
	}

	.io-title {
		font-size: calc(min(10vw, 100px));
		text-align: left;
		font-weight: 600;
		color: $primary;
	}

	.io-sub-title {
		font-size: calc(min(5vw, 24px));
		text-align: left;
		font-weight: 600;
		color: $primary;
	}

	.io-text {
		font-size: calc(min(5vw, 20px));
		color: $dark;
		text-align: justify;
		line-height: 2;
	}

	.project-code {
		font-size: 12px;
		text-align: left;
		margin-bottom: 10px;
		margin-top: 10px;
	}

	.img-label {
		font-size: 12px;
		text-align: left;
	}

	.bottom-div {
		position: sticky;
		bottom: 0;
		background-color: white;
		width: 100%;
	}

	.img-div {
		width: 90%;
		border-radius: 30px;
		margin-left: auto;
		margin-right: auto;
	}

	.button {
		float: left;
	}

	.banner {
		left: 0;
		bottom: 0;
		border-radius: 30px;
	}
</style>
