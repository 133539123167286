<template>
	<scrollactive
		:scrollOffset="60"
		:offset="200"
		:highlightFirstItem="true"
		:duration="1000"
		:itemchanged="onItemChanged"
		:active-class="'selected-navbar-item'"
		class="navbar is-fixed-top"
	>
		<div class="navbar-brand">
			<a class="navbar-item" href="/">
				<img class="logo" src="../assets/logos/logo.png" />
			</a>

			<a
				role="button"
				class="navbar-burger burger"
				:class="{ 'is-active': isActive }"
				@click="changeBurger()"
				aria-label="menu"
				aria-expanded="false"
				data-target="navbarBasicExample"
			>
				<span aria-hidden="true"></span>
				<span aria-hidden="true"></span>
				<span aria-hidden="true"></span>
			</a>
		</div>

		<div id="navbarBasicExample" class="navbar-menu" :class="{ 'is-active': isActive }">
			<div class="navbar-start">
				<a class="navbar-item scrollactive-item" @click="changeBurger" href="#home">
					<p>{{ $t('navbar.home') }}</p>
				</a>

				<a class="navbar-item scrollactive-item" @click="changeBurger" href="#approvals">
					<p>{{ $t('navbar.approval') }}</p>
				</a>

				<a class="navbar-item scrollactive-item" @click="changeBurger" href="#project">
					<p>{{ $t('navbar.project') }}</p>
				</a>
				<a class="navbar-item scrollactive-item" @click="changeBurger" href="#solution">
					<p>{{ $t('navbar.solution') }}</p>
				</a>
				<a class="navbar-item scrollactive-item" @click="changeBurger" href="#materials">
					<p>{{ $t('navbar.materials') }}</p>
				</a>
				<a class="navbar-item scrollactive-item" @click="changeBurger" href="#news">
					<p>{{ $t('navbar.news') }}</p>
				</a>
				<a class="navbar-item scrollactive-item" @click="changeBurger" href="#team">
					<p>{{ $t('navbar.team') }}</p>
				</a>
				<a class="navbar-item scrollactive-item" @click="changeBurger" href="#partners">
					<p>{{ $t('navbar.partners') }}</p>
				</a>

				<div class="navbar-item"></div>
				<div class="navbar-item">
					<!--Portuguese-->

					<img class="image is-24x24" :class="{ 'lang-active': language === 'en' }" src="../assets/flags/portugal.svg" @click="changeLanguage('pt')" />
				</div>
				<div class="navbar-item">
					<!-- English -->
					<img class="image is-24x24" :class="{ 'lang-active': language === 'pt' }" src="../assets/flags/united-kingdom.svg" @click="changeLanguage('en')" />
				</div>
			</div>

			<div class="navbar-end"></div>
		</div>
	</scrollactive>
</template>

<script>
	import { language } from '../mixins/modules/language.js';

	export default {
		name: 'NavBar',
		mixins: [language],
		data() {
			return {
				isActive: false,
				show: false,
				visible: false,
			};
		},
		created() {},
		methods: {
			changeLanguage(language) {
				this.$store.commit('language/setLanguage', language);
				this.$i18n.locale = language;
			},
			changeBurger() {
				this.isActive = !this.isActive;
			},
			onItemChanged(event, currentItem, lastActiveItem) {
				this.changeBurger();
			},
		},
	};
</script>

<style scoped lang="scss">
	@import '@/assets/scss/_variables';
	.navbar {
		height: 60px;
		background-color: rgba(255, 255, 255, 0.92);
	}

	.selected-navbar-item {
		border-bottom: 5px solid $primary !important;
		margin-bottom: 5px;
	}

	.lang-active {
		opacity: 0.4 !important;
	}

	.image {
		opacity: 1;
		margin-left: auto;
		margin-right: auto;
		cursor: pointer;
	}

	.navbar-burger {
		color: $primary;
		span {
			color: inherit !important;
		}
	}

	h3 {
		margin-top: auto !important;
		margin-bottom: auto !important;
		font-size: 1rem !important;
		color: $black !important;
		font-weight: bolder !important;
	}
</style>
