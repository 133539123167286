<template>
	<section id="news" class="hero first-page">
		<p class="title">{{ $t('pages.news.title') }}</p>
		<div class="div-news">
			<carousel
				:perPageCustom="[
					[0, 1],
					[768, 2],
					[1024, 3],
				]"
				:minSwipeDistance="50"
				paginationActiveColor="#a351bd"
				:paginationPadding="5"
			>
				<slide v-for="(post, i) in posts" :key="i">
					<div class="card" @click="openPost(post)">
						<div class="card-image">
							<figure class="image">
								<img :src="post.jetpack_featured_media_url" />
							</figure>
						</div>
						<div class="card-content">
							<p class="subtitle">
								<b>{{ post.title.rendered }}</b>
							</p>
							<p class="post-date">{{ post.date_gmt }}</p>
							<div class="content" v-html="post.excerpt.rendered"></div>
						</div>
					</div>
				</slide>
			</carousel>
		</div>
		<transition name="fade">
			<news-modal @toParent="handler" v-if="visible" :post="selectePost"></news-modal>
		</transition>
	</section>
</template>

<script>
	import { getPosts } from '../api/apiNews';
	import NewsModal from './expandedNews/NewsModal';

	export default {
		name: 'News',
		components: {
			NewsModal,
		},
		data() {
			return {
				animated: 'slide',
				autoPlay: false,
				pauseHover: false,
				pauseInfo: false,
				loading: true,
				posts: [],
				selectePost: null,
				visible: false,
			};
		},
		created() {
			getPosts()
				.then((response) => {
					this.posts = response.data;
					for (let i = 0; i < this.posts.length; i++) {
						this.posts[i].date_gmt = this.posts[i].date_gmt.substr(0, this.posts[i].date_gmt.lastIndexOf('T'));
					}
				})
				.catch((error) => {});
		},
		methods: {
			openPost(post) {
				this.selectePost = post;
				this.visible = true;
			},
			handler() {
				this.visible = false;
			},
		},
	};
</script>

<style scoped lang="scss">
	@import '@/assets/scss/_variables';
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.5s;
	}

	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}

	.title {
		margin-top: 50px;
		margin-bottom: 0;
		font-weight: 600;
		color: white;
	}

	.card {
		border-radius: 20px;
		margin-right: 15px;
		margin-left: 15px;
		cursor: pointer;
		height: 100%;
		-webkit-box-shadow: 0 0.5em 1em -0.125em rgba(255, 255, 255, 0.3), 0 0px 0 1px rgba(255, 255, 255, 0.02);
		-moz-box-shadow: 0 0.5em 1em -0.125em rgba(255, 255, 255, 0.3), 0 0px 0 1px rgba(255, 255, 255, 0.02);
		box-shadow: 0 0.5em 1em -0.125em rgba(255, 255, 255, 0.3), 0 0px 0 1px rgba(255, 255, 255, 0.02);
	}

	img {
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		height: 200px;
		object-fit: cover;
	}

	.post-date {
		font-size: 12px;
		text-align: left;
		margin-bottom: 10px;
		margin-top: 10px;
	}

	.content {
		text-align: justify;
	}

	.div-news {
		margin-top: 50px;
		margin-bottom: 50px;
		margin-left: 10%;
		margin-right: 10%;
	}

	.first-page {
		background-color: $primary;
	}
</style>
