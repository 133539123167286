<template>
	<section id="team" class="first-page">
		<p class="title">{{ $t('pages.team.title') }}</p>
		<div class="io-team columns is-multiline is-mobile">
			<!--Filipe Portela-->
			<div :style="{ order: getRandomNumber() }" class="column is-3-desktop is-6-tablet is-12-mobile">
				<div>
					<img src="../assets/images/team/FilipePortela.png" alt="Avatar" class="image" />
					<div class="overlay">
						<div class="text">
							<p>
								<b>{{ $t('pages.team.names.key1') }}</b>
							</p>
							<br />
							<p>{{ $t('pages.team.roles.key1') }}</p>
						</div>
					</div>
				</div>
			</div>

			<!--Igor Fernandes-->
			<div :style="{ order: getRandomNumber() }" class="column is-3-desktop is-6-tablet is-12-mobile">
				<div>
					<img src="../assets/images/team/IgorFernandes.png" alt="Avatar" class="image" />
					<div class="overlay">
						<div class="text">
							<p>
								<b>{{ $t('pages.team.names.key2') }}</b>
							</p>
							<br />
							<p>{{ $t('pages.team.roles.key2') }}</p>
						</div>
					</div>
				</div>
			</div>

			<!--José Vieira-->
			<div :style="{ order: getRandomNumber() }" class="column is-3-desktop is-6-tablet is-12-mobile">
				<div>
					<img src="../assets/images/team/JoseVieira.png" alt="Avatar" class="image" />
					<div class="overlay">
						<div class="text">
							<p>
								<b>{{ $t('pages.team.names.key3') }}</b>
							</p>
							<br />
							<p>{{ $t('pages.team.roles.key3') }}</p>
						</div>
					</div>
				</div>
			</div>

			<!--Daniel Carneiro-->
			<div :style="{ order: getRandomNumber() }" class="column is-3-desktop is-6-tablet is-12-mobile">
				<div>
					<img src="../assets/images/team/DanielCarneiro.png" alt="Avatar" class="image" />
					<div class="overlay">
						<div class="text">
							<p>
								<b>{{ $t('pages.team.names.key4') }}</b>
							</p>
							<br />
							<p>{{ $t('pages.team.roles.key4') }}</p>
						</div>
					</div>
				</div>
			</div>

			<!--Carlos Fernandes-->
			<div :style="{ order: getRandomNumber() }" class="column is-3-desktop is-6-tablet is-12-mobile">
				<div>
					<img src="../assets/images/team/CF.png" alt="Avatar" class="image" />
					<div class="overlay">
						<div class="text">
							<p>
								<b>{{ $t('pages.team.names.key5') }}</b>
							</p>
							<br />
							<p>{{ $t('pages.team.roles.key5') }}</p>
						</div>
					</div>
				</div>
			</div>

			<!--Sara Furtado-->
			<div :style="{ order: getRandomNumber() }" class="column is-3-desktop is-6-tablet is-12-mobile">
				<div>
					<img src="../assets/images/team/Sara.png" alt="Avatar" class="image" />
					<div class="overlay">
						<div class="text">
							<p>
								<b>{{ $t('pages.team.names.key6') }}</b>
							</p>
							<br />
							<p>{{ $t('pages.team.roles.key6') }}</p>
						</div>
					</div>
				</div>
			</div>
			<!--Rita Miranda-->
			<div :style="{ order: getRandomNumber() }" class="column is-3-desktop is-6-tablet is-12-mobile">
				<div>
					<img src="../assets/images/team/rmm.png" alt="Avatar" class="image" />
					<div class="overlay">
						<div class="text">
							<p>
								<b>{{ $t('pages.team.names.key10') }}</b>
							</p>
							<br />
							<p>{{ $t('pages.team.roles.key10') }}</p>
						</div>
					</div>
				</div>
			</div>
			<!--Beatriz Pereira-->
			<div :style="{ order: getRandomNumber() }" class="column is-3-desktop is-6-tablet is-12-mobile">
				<div>
					<img src="../assets/images/team/BP.png" alt="Avatar" class="image" />
					<div class="overlay">
						<div class="text">
							<p>
								<b>{{ $t('pages.team.names.key7') }}</b>
							</p>
							<br />
							<p>{{ $t('pages.team.roles.key7') }}</p>
						</div>
					</div>
				</div>
			</div>
			<!--Beatriz Rodrigues-->
			<div :style="{ order: getRandomNumber() }" class="column is-3-desktop is-6-tablet is-12-mobile">
				<div>
					<img src="../assets/images/team/br.png" alt="Avatar" class="image" />
					<div class="overlay">
						<div class="text">
							<p>
								<b>{{ $t('pages.team.names.key8') }}</b>
							</p>
							<br />
							<p>{{ $t('pages.team.roles.key8') }}</p>
						</div>
					</div>
				</div>
			</div>
			<!--Joao Oliveira-->
			<div :style="{ order: getRandomNumber() }" class="column is-3-desktop is-6-tablet is-12-mobile">
				<div>
					<img src="../assets/images/team/JO.png" alt="Avatar" class="image" />
					<div class="overlay">
						<div class="text">
							<p>
								<b>{{ $t('pages.team.names.key9') }}</b>
							</p>
							<br />
							<p>{{ $t('pages.team.roles.key9') }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<b-collapse animation="slide" class="collapse" :open.sync="isCollapseOpen">
			<template #trigger="props">
				<p class="title is-uppercase">
					{{ $t('pages.team.open') }}
					<b-icon size="is-medium" :icon="isCollapseOpen ? 'chevron-down' : 'chevron-up'"></b-icon>
				</p>
			</template>
			<div>
				<h2>02/02/2022 - 15/02/2022</h2>
				<h2>Ref. PT2020-FEDER-045397-IOCITY</h2>
			</div>
			<div class="columns is-multiline is-mobile">
				<div class="column is-4-desktop is-12-mobile is-6-tablet">
					<div class="card is-clickable" @click="open(1)">
						<div class="card-image has-text-centered-webkit">
							<figure class="image is-128x128">
								<img src="../assets/images/team/account.svg" />
							</figure>
						</div>
						<div class="card-content">
							<div class="content">
								<p class="subtitle">
									<b>{{ $t('pages.team.open1') }}</b>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="column is-4-desktop is-12-mobile is-6-tablet">
					<div class="card is-clickable" @click="open(2)">
						<div class="card-image has-text-centered-webkit">
							<figure class="image is-128x128">
								<img src="../assets/images/team/account.svg" />
							</figure>
						</div>
						<div class="card-content">
							<div class="content">
								<p class="subtitle">
									<b>{{ $t('pages.team.open2') }}</b>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="column is-4-desktop is-12-mobile is-6-tablet">
					<div class="card is-clickable" @click="open(3)">
						<div class="card-image has-text-centered-webkit">
							<figure class="image is-128x128">
								<img src="../assets/images/team/account.svg" />
							</figure>
						</div>
						<div class="card-content">
							<div class="content">
								<p class="subtitle">
									<b>{{ $t('pages.team.open3') }}</b>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</b-collapse>
	</section>
</template>

<script>
	export default {
		name: 'Team',
		data() {
			return {
				isCollapseOpen: false,
			};
		},
		methods: {
			open(val) {
				if (val == 1) window.open('https://docs.iocity.research.iotech.pt/edital/MinutaEdital_Bolsa_ioCity_01.pdf', '_blank');
				else if (val == 2) window.open('https://docs.iocity.research.iotech.pt/edital/MinutaEdital_Bolsa_ioCity_02.pdf', '_blank');
				else window.open('https://docs.iocity.research.iotech.pt/edital/MinutaEdital_Bolsa_ioCity_03.pdf', '_blank');
			},
			getRandomNumber() {
				return parseInt(Math.random() * 100);
			},
		},
	};
</script>

<style scoped lang="scss">
	@import '@/assets/scss/_variables';
	@import '~bulma/sass/utilities/mixins.sass';

	.title {
		padding-top: 50px;
		font-weight: 600;
		color: $primary;
	}

	.first-page {
		background-color: white;
	}

	.columns {
		margin: 5% 10% 10% 10% !important;
	}

	.collapse {
		margin-bottom: 10% !important;
	}

	.card {
		border-radius: 20px;
	}

	#team {
		.columns {
			margin-bottom: 0% !important;
			@include touch {
				margin-bottom: 5% !important;
			}
		}

		.collapse {
			margin-bottom: 10% !important;
			@include touch {
				margin-bottom: 15% !important;
                p {
                    margin-left: 5%;
                    margin-right: 5%;
                }
			}
		}
	}

	.io-team {
		.container {
			width: 100%;
			max-width: 340px;
			max-height: 340px;
			min-height: 220px;
			min-width: 220px;
			border-radius: 50%;
			background-color: #f6f6f6;
		}

		.image {
			display: block;
			width: 100%;
			height: auto;
			border-radius: 10000000px;
			max-width: 340px;
			max-height: 340px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		.column {
			aspect-ratio: 1;
			> div {
				height: 100%;
				width: 100%;
				position: relative;
				&:hover .overlay {
					opacity: 0.9;
				}
			}
		}

		.overlay {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			height: 100%;
			width: 100%;
			opacity: 0;
			transition: 0.5s ease;
			background-color: $primary;
			border-radius: 10000000px;
		}

		.text {
			color: white;

			font-size: calc(min(4vw, 16px));

			@include until($mobile) {
				font-size: calc(max(2vw, 11px));
			}

			@include until($mobile-l) {
				font-size: calc(max(4vw, 9px));
			}

			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			text-align: center;
			width: 88%;
			-webkit-user-select: none; /* Safari */
			-moz-user-select: none; /* Firefox */
			-ms-user-select: none; /* IE10+/Edge */
			user-select: none; /* Standard */
		}
	}
</style>
