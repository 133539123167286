import axios from 'axios';

const getHeaders = () => {
	return {};
};

export function post(uri, data) {
	return axios.post(endpoint(uri), data, {
		headers: getHeaders(),
	});
}

export function put(uri, data) {
	return axios.put(endpoint(uri), data, {
		headers: getHeaders(),
	});
}

export function remove(uri, data) {
	return axios.delete(endpoint(uri), {
		data,
		headers: getHeaders(),
	});
}

export function get(uri) {
	return axios.get(endpoint(uri), {
		headers: getHeaders(),
	});
}

export function endpoint(uri) {
	return process.env.VUE_APP_WP_BASE_URL + uri;
}

export function formData(uri, data) {
	let config = {
		headers: getHeaders(),
	};
	config.headers['Content-Type'] = 'multipart/form-data';
	return axios.post(endpoint(uri), data, config);
}
export function formDataPut(uri, data) {
	let config = {
		headers: getHeaders(),
	};
	config.headers['Content-Type'] = 'multipart/form-data';
	return axios.put(endpoint(uri), data, config);
}
