<template>
	<section id="partners" class="hero is-primary mb-4">
		<div class="hero-body">
			<p class="title">{{ $t('pages.partners.title') }}</p>
			<div class="columns is-centered is-multiline is-mobile">
				<div :style="{ order: getRandomNumber() }" class="column is-3-desktop is-6-tablet is-12-mobile">
					<div class="has-position-relative">
						<figure class="image is-round has-background-white mx-auto">
							<img src="@/assets/images/partners/cmvnf.png" />
						</figure>
						<div @click.stop="goTo('https://www.cm-vnfamalicao.pt/')" class="overlay is-clickable is-round has-background-white has-text-primary">
							<div class="text">
								<p class="has-text-weight-bold" v-html="$t('pages.partners.names.key1')"></p>
								<p v-html="$t('pages.partners.roles.key1')"></p>
								<br />
								<a class="is-underlined" @click.stop="goTo('https://www.cm-vnfamalicao.pt/')">{{ $t('pages.partners.openSite') }}</a>
							</div>
						</div>
					</div>
				</div>
				<div :style="{ order: getRandomNumber() }" class="column is-3-desktop is-6-tablet is-12-mobile">
					<div class="has-position-relative">
						<figure class="image is-round has-background-white mx-auto">
							<img src="@/assets/images/partners/centi.png" />
						</figure>
						<div @click.stop="goTo('https://centi.pt/')" class="overlay is-clickable is-round has-background-white has-text-primary">
							<div class="text">
								<p class="has-text-weight-bold" v-html="$t('pages.partners.names.key2')"></p>
								<p v-html="$t('pages.partners.roles.key2')"></p>
								<br />
								<a class="is-underlined" @click.stop="goTo('https://centi.pt/')">{{ $t('pages.partners.openSite') }}</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'Partners',
		methods: {
			goTo(page) {
				window.open(page, '_blank');
			},
			getRandomNumber() {
				return parseInt(Math.random() * 100);
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/_variables';
	@import '~bulma/sass/utilities/mixins.sass';
	figure,
	figure > img {
		width: 100%;
		max-width: 340px;
		max-height: 340px;
		min-height: 220px;
		min-width: 220px;
	}

	.columns {
		margin: 5% 10% 10% 10% !important;
	}

	.column {
		> div {
			&:hover .overlay {
				opacity: 0.9;
			}
		}
	}

	.overlay {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		height: 101%;
		width: 101%;
		opacity: 0;
		transition: 0.5s ease;
	}

	.text {
		font-size: calc(min(4vw, 16px));

		@include until($mobile) {
			font-size: calc(max(2vw, 11px));
		}

		@include until($mobile-l) {
			font-size: calc(max(4vw, 9px));
		}

		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		text-align: center;
		width: 88%;
		-webkit-user-select: none; /* Safari */
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* IE10+/Edge */
		user-select: none; /* Standard */
	}
</style>
