<template>
	<div class="modal is-active">
		<div class="modal-background"></div>
		<div class="modal-card">
			<section class="modal-card-body">
				<button class="delete" @click="closePost" aria-label="close"></button>
				<div class="post-image">
					<img :src="post.jetpack_featured_media_url" />
				</div>

				<p class="modal-card-title">{{ post.title.rendered }}</p>
				<p class="post-date">{{ post.date_gmt }}</p>
				<div class="post-content" v-html="post.content.rendered"></div>
			</section>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ExpandedNews',
		props: {
			post: {
				required: true,
			},
		},
		data() {
			return {};
		},
		created() {
			console.log(this.post);
		},
		methods: {
			closePost() {
				this.$emit('toParent');
			},
		},
	};
</script>

<style scoped>
	.modal-card-body {
		border-radius: 20px;
		padding: 0;
	}

	.post-image {
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		margin-bottom: 15px;
	}

	.modal-card-title {
		margin-left: 20px;
		margin-right: 20px;
		font-weight: 600;
	}

	.post-content {
		text-align: justify;
		margin: 20px;
	}

	.post-date {
		font-size: 12px;
		text-align: left;
		margin-bottom: 10px;
		margin-top: 10px;
		margin-left: 20px;
	}

	.delete {
		position: absolute;
		right: 10px;
		top: 10px;
		z-index: 30;
	}
</style>
