<template>
	<section id="approvals" class="first-page hero io-fullheight">
		<img src="../assets/banner/clouds-white.png" />
		<div class="approval">
			<div class="columns is-multiline">
				<div class="column is-4">
					<p class="io-sub-title">{{ $t('pages.approval.approvalDate') }}</p>
					<p class="io-title">
						{{ $t('projectInfo.approvalDate') }}
					</p>
				</div>
				<div class="column is-4">
					<p class="io-sub-title">{{ $t('pages.approval.beginDate') }}</p>
					<p class="io-title">
						{{ $t('projectInfo.beginDate') }}
					</p>
				</div>
				<div class="column is-4">
					<p class="io-sub-title">{{ $t('pages.approval.endDate') }}</p>
					<p class="io-title">
						{{ $t('projectInfo.endDate') }}
					</p>
				</div>
				<div class="column is-4">
					<p class="io-sub-title">{{ $t('pages.approval.eligibleFund') }}</p>
					<p class="io-title">
						{{ $t('projectInfo.eligibleFund') }}
					</p>
				</div>

				<div class="column is-4">
					<p class="io-sub-title">{{ $t('pages.approval.nationalFund') }}</p>
					<p class="io-title">
						{{ $t('projectInfo.nationalFund') }}
					</p>
				</div>
			</div>
		</div>
		<!--<img class="banner" src="../assets/banner/clouds-white-3.png">-->
		<img class="banner" src="../assets/banner/clouds-white-reverse.png" />
	</section>
</template>

<script>
	export default {
		name: 'Approvals',
		data() {
			return {
				delay: 1000,
				eligibleFund: 50720.78,
				nationalFund: 44526.8,

				options: {
					useEasing: true,
					useGrouping: true,
					duration: 1,
					separator: ',',
					decimalPlaces: 2,
					decimal: '.',
					prefix: '',
					suffix: '€',
				},
			};
		},
		methods: {},
	};
</script>

<style scoped lang="scss">
	@import '@/assets/scss/_variables';

	.io-title {
		font-size: calc(min(7vw, 40px));

		font-weight: 600;
	}

	.io-sub-title {
		font-size: calc(min(4vw, 20px));
		line-height: 2;
	}

	.approval {
		width: 100%;
		position: fixed;
		z-index: -1;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	p {
		color: white;
	}

	.first-page {
		background-color: $primary;
		position: relative;
		z-index: -2;
	}
</style>
