<template>
	<section>
		<app-home></app-home>
		<app-approvals></app-approvals>
		<app-project></app-project>
		<app-solution></app-solution>
		<app-materials></app-materials>
		<app-news></app-news>
		<app-team></app-team>
        <Partners />
	</section>
</template>

<script>
	import Home from '../components/Home';
	import Approvals from '../components/Approvals';
	import Project from '../components/Project';
	import Team from '../components/Team';
	import Solution from '../components/Solution';
	import News from '../components/News';
	import Materials from '../components/Materials';
	import Partners from '../components/Partners';

	export default {
		name: 'Home',
		components: {
			appHome: Home,
			appApprovals: Approvals,
			appProject: Project,
			appSolution: Solution,
			appTeam: Team,
			appNews: News,
			appMaterials: Materials,
            Partners
		},
		data() {
			return {};
		},
		methods: {},
	};
</script>

<style scoped></style>
