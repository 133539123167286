<template>
	<section id="materials" class="first-pages">
		<p class="title">{{ $t('pages.materials.title') }}</p>
		<div class="columns is-multiline">
			<div class="column is-4 image has-text-centered-webkit">
				<a :href="`${publicPath}logos_iocity.zip`" target="_blank" download> 
                    <figure class="image is-128x128">
                        <img src="../assets/logos/logo_.svg" alt="Manual" class="image" />
                    </figure>
                     </a
				><br />
				<p>{{ $t('pages.materials.resources.title2') }}</p>
			</div>
			<!-- <div class="column is-4">
				<a href="https://app.iocovid19.research.iotech.pt/" target="_blank"> <img src="../assets/materials/covidAPP.png" alt="APP" class="image" /> </a><br />
				<p>{{ $t('pages.materials.resources.title3') }}</p>
			</div> -->
		</div>
	</section>
</template>

<script>
	export default {
		name: 'Materials',
		data() {
			return {
				publicPath: process.env.BASE_URL,
			};
		},
		methods: {},
	};
</script>

<style scoped lang="scss">
	@import '@/assets/scss/_variables';
	.title {
		margin-top: 50px;
		font-weight: 600;
		color: $primary;
	}

	.columns {
		margin: 50px 10% 50px 10% !important;
	}

	.column {
		margin-bottom: 30px;
	}

	.image {
		border-radius: 20px;
		-webkit-box-shadow: 0 0.5em 1em -0.125em rgba(1, 172, 202, 0.3), 0 0px 0 1px rgba(1, 172, 202, 0.02);
		-moz-box-shadow: 0 0.5em 1em -0.125em rgba(1, 172, 202, 0.3), 0 0px 0 1px rgba(1, 172, 202, 0.02);
		box-shadow: 0 0.5em 1em -0.125em rgba(1, 172, 202, 0.3), 0 0px 0 1px rgba(1, 172, 202, 0.02);
	}

    .has-text-centered-webkit {
        text-align: -webkit-center;
    }

	.first-page {
		background-color: white;
	}
</style>
