<template>
	<div id="app">
		<Navbar></Navbar>
		<router-view />
		<Footer></Footer>
	</div>
</template>

<script>
	import Navbar from './views/Navbar';
	import Footer from './views/Footer';

	export default {
		components: {
			Navbar,
			Footer,
		},
		data() {
			return {
				hasInternet: true,
			};
		},
		created() {},
		mounted() {
			window.addEventListener('online', () => {
				this.hasInternet = true;
			});
			window.addEventListener('offline', () => {
				this.hasInternet = false;
			});
		},
	};
</script>

<style lang="scss">
	@import './assets/scss/app';

	#app {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: $dark;
	}

	#nav {
		padding: 30px;

		a {
			font-weight: bold;
			color: $blue;

			&.router-link-exact-active {
				color: $dark;
				text-decoration: underline;
			}
		}
	}

	/* Custom style */

	.button {
		padding: 0.8rem 1.8rem 0.8rem 1.8rem;
		margin: 0.2rem 0.2rem 0.2rem 0.2rem;
		font-family: inherit;
		font-size: 1rem;
		cursor: pointer;
		border-radius: 0.2rem;
	}

	.button-light {
		background-color: #dfdfdf;
	}

	.button:hover {
		opacity: 0.7;
	}
</style>
