<template>
	<footer class="footer">
		<div class="content has-text-centered">
			<img class="banner" src="../assets/banner/barra_feder_footer.png" />
			<p class="has-text-white">{{ $t('footer.key1') }}<br />{{ $t('footer.key2', { currentYear }) }}</p>
			.
		</div>
	</footer>
</template>

<script>
	export default {
		name: 'Footer',
		data() {
			return {
				currentYear: new Date().getFullYear(),
			};
		},
	};
</script>

<style scoped lang="scss">
	@import '@/assets/scss/_variables';
	.footer {
		background-color: $primary;
		padding: 0;
	}

	img {
		margin-top: 15px;
		margin-bottom: 15px;
		width: 80%;
	}

	p {
		font-size: 11px;
	}
</style>
