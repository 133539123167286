import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import Buefy from 'buefy';
import './assets/scss/app.scss';
import i18n from './i18n';

Vue.use(Buefy);

import VueScrollTo from 'vue-scrollto';

Vue.use(VueScrollTo);

import VueCarousel from 'vue-carousel';

Vue.use(VueCarousel);

import VueScrollactive from 'vue-scrollactive';

Vue.use(VueScrollactive);
Vue.config.productionTip = false;

import "./mixins/index.js";

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount('#app');
