<template>
	<section id="solution" class="hero first-page">
		<p class="title">{{ $t('pages.solution.title') }}</p>
		<div class="columns is-multiline">
			<div class="column is-4">
				<div class="card">
					<div class="card-content">
						<img src="../assets/logos/Adaptive.png" />
						<p class="subtitle">
							<b>{{ $t('pages.solution.cards.key1') }}</b>
						</p>
					</div>
				</div>
			</div>

			<div class="column is-4">
				<div class="card">
					<div class="card-content">
						<img src="../assets/logos/Innovative.png" />
						<p class="subtitle">
							<b>{{ $t('pages.solution.cards.key2') }}</b>
						</p>
					</div>
				</div>
			</div>

			<div class="column is-4">
				<div class="card">
					<div class="card-content">
						<img src="../assets/logos/Intelligent.png" />
						<p class="subtitle">
							<b>{{ $t('pages.solution.cards.key3') }}</b>
						</p>
					</div>
				</div>
			</div>

			<div class="column is-4">
				<div class="card">
					<div class="card-content">
						<img src="../assets/logos/Multiplatform.png" />
						<p class="subtitle">
							<b>{{ $t('pages.solution.cards.key4') }}</b>
						</p>
					</div>
				</div>
			</div>

			<div class="column is-4">
				<div class="card">
					<div class="card-content">
						<img src="../assets/logos/SaaS.png" />
						<p class="subtitle">
							<b>{{ $t('pages.solution.cards.key5') }}</b>
						</p>
					</div>
				</div>
			</div>

			<div class="column is-4">
				<div class="card">
					<div class="card-content">
						<img src="../assets/logos/Scalable.png" />
						<p class="subtitle">
							<b>{{ $t('pages.solution.cards.key6') }}</b>
						</p>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'Solution',
		data() {
			return {};
		},
		methods: {},
	};
</script>

<style scoped lang="scss">
	@import '@/assets/scss/_variables';
	.title {
		margin-top: 50px;
		margin-bottom: 0;
		font-weight: 600;
		color: $white;
	}

	.columns {
		margin: 50px 10% 50px 10% !important;
	}

	.card {
		border-radius: 20px;
		-webkit-box-shadow: 0 0.5em 1em -0.125em rgba(255, 255, 255, 0.3), 0 0px 0 1px rgba(255, 255, 255, 0.02);
		-moz-box-shadow: 0 0.5em 1em -0.125em rgba(255, 255, 255, 0.3), 0 0px 0 1px rgba(255, 255, 255, 0.02);
		box-shadow: 0 0.5em 1em -0.125em rgba(255, 255, 255, 0.3), 0 0px 0 1px rgba(255, 255, 255, 0.02);
	}

	img {
		width: 30%;
	}

	.first-page {
		background-color: $primary;
	}
</style>
